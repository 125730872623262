import {ApiClient} from "@/api/calculations/api-client";
import {Utils} from "@/utils/Utils";
import {MoonPhases} from "@/config/moon-phases";
import {Sources} from "@/config/sources";
import {Constants, NightReliabilityTypes} from "@/utils/constants";

const state = {
    loading: false,
    loadingForecasts: false,
    location: null,
    forecasts: null,
    dailyOverall: null,
    forecastsLink: null,
    reliabilities: null,
    nightReliabilityCharts: null,
    forecastTrend: null,
    forecastStatus: null,
    regionalForecast: null,
    dailyChart: null,
    displayedDailyChartDiffType: 'diff-abs',
    pastHoursVerification: null,
    moon: null,
    sun: null,
    night: null,
    displayedDate: null,
    pageTitle: null,
    latitude: null,
    longitude: null,
    countryCode: null,
    homeLocationDisplayed: false,
    daysOffsetFromToday: 0, // TODO maybe use the calculated index based on displayed date?
    daysOffsetFromTodayPrev: 0,
    dayMovement: null, // ONE_LEFT,ONE_RIGHT
    cloudOptions: [
        { text: 'Celková', value: 'total', disabled: false },
        { text: 'Vysoká', value: 'high', disabled: true },
        { text: 'Střední', value: 'middle', disabled: true },
        { text: 'Nízká', value: 'low', disabled: true }
    ],
    displayedCloudType: 'total',
    centerOnMidnight: loadCenterOnMidnight(),
    sortByReliability: loadSortByReliability(),
    forecastAvgMode: loadForecastAvgMode(),
    forecastReliabilityRadius: loadForecastReliabilityRadius(),
    forecastReliabilityType: loadForecastReliabilityType(),
    nightReliabilityDays: loadNightReliabilityDays(),
    regionalForecastTime: null,
    displayMoon: true,
    forecastRqSentTs: 0,
    nightFromHour: loadNightFromHour(),
    nightToHour: loadNightToHour(),
}

function loadCenterOnMidnight() {
    const value = localStorage.getItem("centerOnMidnight");
    if (value === null || value === undefined) {
        return true;
    }
    const resolved = value === true || value === 'true';
    // console.log("loadCenterOnMidnight = " + resolved)
    return resolved;
}

function loadSortByReliability() {
    const value = localStorage.getItem("sortByReliability");
    if (value === null || value === undefined) {
        return true;
    }
    const resolved = value === true || value === 'true';
    // console.log("loadSortByReliability = " + resolved)
    return resolved;
}

function loadForecastAvgMode() {
    const value = localStorage.getItem("forecastAvgMode");
    // console.log("loadForecastAvgMode = " + value)
    if (!value) {
        // console.log("returning ALL")
        return 'ALL';
    }
    // console.log("returning " + value)
    return value;
}

function loadForecastReliabilityRadius() {
    const value = localStorage.getItem("forecastReliabilityRadius");
    console.log("loadForecastReliabilityRadius = " + value)
    if (!value) {
        console.log("loadForecastReliabilityRadius RADIUS_100")
        return 'RADIUS_100';
    }
    console.log("loadForecastReliabilityRadius " + value)
    return value;
}

function loadForecastReliabilityType() {
    const value = localStorage.getItem("forecastReliabilityType");
    console.log("loadForecastReliabilityType = " + value)
    if (!value) {
        console.log("loadForecastReliabilityType LAST_12_AND_3_HOURS")
        return 'LAST_12_AND_3_HOURS';
    }
    console.log("loadForecastReliabilityType " + value)
    return value;
}

function loadNightReliabilityDays() {
    const value = localStorage.getItem("nightReliabilityDays");
    // console.log("forecastReliabilityRadius = " + value)
    if (!value) {
        // console.log("returning forecastReliabilityRadius RADIUS_WHOLE_COUNTRY")
        return 21;
    }
    // console.log("returning " + value)
    return value;
}

function loadNightFromHour() {
    return localStorage.getItem("nightFromHour");
}

function loadNightToHour() {
    return localStorage.getItem("nightToHour");
}

const getters = {

    isLoading: (state) => {
        return state.loading;
    },

    isLoadingForecasts: (state) => {
        return state.loadingForecasts;
    },

    getPageTitle: (state) => {
        return state.pageTitle;
    },

    isDisplayedWholeCountry: (state) => {
        return state.pageTitle === 'Česká republika';
    },

    getLatitude: (state) => {
        return state.latitude;
    },


    getLongitude: (state) => {
        return state.longitude;
    },

    homeLocationDisplayed: (state) => {
        return state.homeLocationDisplayed;
    },

    getForecastDate: (state) => {
        if (state.displayedDate) {
            return state.displayedDate;
        }
        return Utils.todayDate();
    },

    getForecastRequestDays: (state) => {
        const current = state.daysOffsetFromToday + 1;
        if (state.centerOnMidnight) {
            return [current, current + 1];
        } else {
            return [current];
        }
    },

    // TODO rework ...
    previousDayExists: (state, getters) => {
        if (getters.getCurrentDateForecasts) {
            return getters.getCurrentDateForecasts.previousDayExists;
        }
        return null;
    },

    nextDayExists: (state, getters) => {
        if (getters.getCurrentDateForecasts) {
            return getters.getCurrentDateForecasts.nextDayExists;
        }
        return null;
    },

    getDaysOffsetFromToday: (state) => {
        return state.daysOffsetFromToday;
    },

    todayForecastDisplayed: (state) => {
        return state.daysOffsetFromToday === 0;
    },

    getDayMovement: (state) => {
        return state.dayMovement;
    },

    getForecasts: (state) => {
        return state.forecasts;
    },

    getPastHoursVerifications: (state) => {
        return state.pastHoursVerification;
    },

    // IMPORTANT: rootGetters must precede rootState even if not used!
    getPastHoursVerification: (state, rootGetters, rootState) => (hours) => {
        // console.dir(rootState);
        const displayedSources = rootState.settingsStore.displayedSources;
        const hourVerifications = state.pastHoursVerification.pastHoursList.filter(ph => ph.pastHours === hours)[0];
        return hourVerifications.verifications.filter(v => displayedSources.includes(v.source));
    },

    pastHoursVerificationExistFor: (state) => (hours) => {
        // console.log(hours)
        if (state.pastHoursVerification) {
            let ph = state.pastHoursVerification.pastHoursList.filter(ph => ph.pastHours === hours);
            if (ph[0].verifications.length > 0) {
                // console.log("returning true ...")
                return true;
            }
        }
        // console.log("returning false ...")
        return false;
    },

    getDailyOverall: (state) => {
        return state.dailyOverall;
    },

    getForecastsLink: (state) => {
        return state.forecastsLink;
    },

    getReliabilities: (state) => {
        return state.reliabilities;
    },

    getSourceReliability: (state) => (sourceCode) => {
        if (state.reliabilities
            && state.reliabilities.values
            && state.reliabilities.values[sourceCode]) {
            return state.reliabilities.values[sourceCode];
        } else {
            return null;
        }
    },

    getForecastTrend: (state) => {
        return state.forecastTrend;
    },

    getForecastStatusType: (state) => {
        if (state.forecastStatus) {
            return state.forecastStatus.type;
        }
        return null;
    },

    getForecastStatus: (state) => {
        return state.forecastStatus;
    },

    getRegionalForecast: (state) => {
        return state.regionalForecast;
    },

    getRegionalForecastTimes: (state) => {
        if (state.regionalForecast) {
            return state.regionalForecast.times;
        }
        return null;
    },

    getRegionalForecastExists: (state) => {
        if (state.regionalForecast && state.regionalForecast.times) {
            return state.regionalForecast.times.length > 0;
        }
        return false;
    },

    getRegionalForecastByRegionAndHour: (state) => (region, hour) => {
        if (!hour) {
            hour = state.regionalForecastTime;
        }
        // console.log(region + " - " + hour);
        if (hour) {
            const result = state.regionalForecast.hourlyForecasts
                .find((value) => value.region === region && value.hour === hour);
            return result;
        }
        return null;
    },

    getRegionalForecastTime: (state) => {
        return state.regionalForecastTime;
    },

    getMoon: (state) => {
        return state.moon;
    },

    getMoonIllumination: (state) => {
        if (state.moon) {
            return state.moon.illuminated;
        }
        return null;
    },

    getSun: (state) => {
        return state.sun;
    },

    getNight: (state) => {
        return state.night;
    },

    getDailyChart: (state) => {
        return state.dailyChart;
    },

    getDisplayedDailyChartDiffType: (state) => {
        return state.displayedDailyChartDiffType;
    },

    // IMPORTANT: rootGetters must precede rootState even if not used!
    getDailyValues: (state, rootGetters, rootState) => (source) => {
        if (state.dailyChart) {
            const displayedSources = rootState.settingsStore.displayedSources;
            // console.log("displayedSources: " + displayedSources)
            let sourceSeries = state.dailyChart.series.filter(s => s.source === source && displayedSources.includes(source));
            if (sourceSeries && sourceSeries.length > 0 && sourceSeries[0].values) {
                const valueChooser = val => state.displayedDailyChartDiffType === 'diff' ? val.avgDiff : val.avgDiffAbs;
                return sourceSeries[0].values.map(v => ({
                    x: v.date,
                    y: valueChooser(v)
                }));
            }
        }
        return [];
    },

    getReliabilityValues: (state) => {
        if (state.reliabilities && state.reliabilities.values) {
            return state.reliabilities.values;
        } else {
            return null;
        }
    },

    getReliabilityMeasurementCount: (state) => (radius) => {
        if (state.reliabilities && state.reliabilities.measurementCounts) {
            return state.reliabilities.measurementCounts[radius];
        }
        return null;
    },

    getReliabilityMeasurementCountForType: (state) => (type) => {
        if (state.reliabilities && state.reliabilities.measurementCountsByReliabilityType) {
            return state.reliabilities.measurementCountsByReliabilityType[type];
        }
        return null;
    },

    getSourceCodesByReliabilityDesc: (state, getters) => {
        if (getters.getReliabilityValues) {
            const integerFields = Object.entries(getters.getReliabilityValues)
                // eslint-disable-next-line no-unused-vars
                .filter(([key, value]) => Number.isInteger(value.reliability))
                .reduce((acc, [key, value]) => {
                    acc[key] = value.reliability;
                    return acc;
                }, {});

            // Sort the field names based on their integer values
            const sorted = Object.keys(integerFields).sort((a, b) => integerFields[b] - integerFields[a]);
            return sorted;
        } else {
            return [];
        }
    },

    getNightReliabilityCharts: (state) => {
        return state.nightReliabilityCharts;
    },

    getNightReliabilityChart: (state)=> (type) => {
        if (NightReliabilityTypes.CLEAR_SKY === type) {
            return state.nightReliabilityCharts.clearNightChart;
        } else if (NightReliabilityTypes.CLOUDY_SKY === type) {
            return state.nightReliabilityCharts.cloudyNightChart;
        } else {
            return null;
        }
    },

    getClearNightReliabilityValues: (state, rootGetters) => {
        return rootGetters.getNightReliabilityValues(NightReliabilityTypes.CLEAR_SKY);
    },

    getCloudyNightReliabilityValues: (state, rootGetters) => {
        return rootGetters.getNightReliabilityValues(NightReliabilityTypes.CLOUDY_SKY);
    },

    // IMPORTANT: rootGetters must precede rootState even if not used!
    getNightReliabilityValues: (state, rootGetters, rootState) => (type) => {
        // console.log("inside getNightReliabilityValues " + type)
        if (state.nightReliabilityCharts) {
            let chart = rootGetters.getNightReliabilityChart(type);
            if (chart) {
                const displayedSources = rootState.settingsStore.displayedSources;
                return chart.values
                    .filter(v => displayedSources.includes(v.source))
                    .sort(function (v1, v2) {
                        // Important: titles need to be sorted the same way!
                        return v2.reliability - v1.reliability;
                    })
                    .map(v => {
                        return v.reliability > 0 ? v.reliability : Constants.DUMMY_ZERO;
                    });
            }
        }
        return [];
    },

    getClearNightReliabilityTitles: (state, rootGetters) => {
        return rootGetters.getNightReliabilityTitles(NightReliabilityTypes.CLEAR_SKY);
    },

    getCloudyNightReliabilityTitles: (state, rootGetters) => {
        return rootGetters.getNightReliabilityTitles(NightReliabilityTypes.CLOUDY_SKY);
    },

    // IMPORTANT: rootGetters must precede rootState even if not used!
    getNightReliabilityTitles: (state, rootGetters, rootState) => (type) => {
        // console.log("inside getNightReliabilityTitles: " + state.longtermComparisonChart)
        if (state.nightReliabilityCharts) {
            let chart = rootGetters.getNightReliabilityChart(type);
            if (chart) {
                const displayedSources = rootState.settingsStore.displayedSources;
                let titles = chart.values
                    .filter(v => displayedSources.includes(v.source))
                    .sort(function (v1, v2) {
                        return v2.reliability - v1.reliability;
                    })
                    .map(v => Sources.displayName(v.source));
                // console.log("returning ... : " + titles);
                return titles;
            }
        }
        return [];
    },

    getSourceForecasts: (state) => {
        if (state.forecasts) {
            return state.forecasts.sourceForecasts;
        }
        return [];
    },

    getCurrentDateForecasts: (state, getters) => {
        if (state.forecasts) {
            const forecastDate = Utils.toDate_yyyyMMdd(getters.getForecastDate);
            const dateForecasts = state.forecasts.filter(f => f.date === forecastDate); // one item
            if (dateForecasts && dateForecasts.length === 1) {
                return dateForecasts[0];
            }
        }
        return null;
    },

    getNextDateForecasts: (state, getters) => {
        if (state.forecasts) {
            const currDate = Utils.toDate_yyyyMMdd(getters.getForecastDate);
            const nextDate = Utils.toDate_yyyyMMdd(Utils.offsetDate(currDate, 1));
            const dateForecasts = state.forecasts.filter(f => f.date === nextDate); // one item
            if (dateForecasts && dateForecasts.length === 1) {
                return dateForecasts[0];
            }
        }
        return null;
    },

    getSourceForecast: (state, getters) => (sourceCode, hour) => {
        let dateForecasts;
        if (!state.centerOnMidnight || hour >= 12) {
            dateForecasts = getters.getCurrentDateForecasts;
        } else {
            dateForecasts = getters.getNextDateForecasts;
        }
        if (dateForecasts) {
            const found = dateForecasts.sourceForecasts.filter(f => f.source === sourceCode);
            if (found.length === 1) {
                return found[0];
            }
        }
        return null;
    },

    getDetailedForecast: (state, getters) => (hour) => {
        let dateForecasts;
        if (!state.centerOnMidnight || hour >= 12) {
            dateForecasts = getters.getCurrentDateForecasts;
        } else {
            dateForecasts = getters.getNextDateForecasts;
        }
        if (dateForecasts) {
            return dateForecasts.forecastDetailed;
        }
        return null;
    },

    getDyHourIntervals: (state, getters) => (hour) => {
        let dateForecasts;
        if (!state.centerOnMidnight || hour >= 12) {
            dateForecasts = getters.getCurrentDateForecasts;
        } else {
            dateForecasts = getters.getNextDateForecasts;
        }
        if (dateForecasts) {
            if (dateForecasts.dayHourIntervals) {
                return dateForecasts.dayHourIntervals[hour];
            }
        }
        return null;
    },

    getMoonHours: (state, getters) => (hour) => {
        // TODO actually we do care about date here !!!
        let dateForecasts;
        if (!state.centerOnMidnight || hour >= 12) {
            dateForecasts = getters.getCurrentDateForecasts;
        } else {
            dateForecasts = getters.getNextDateForecasts;
        }
        if (dateForecasts) {
            return dateForecasts.moonHours;
        }
        return null;
    },

    showMoon: (state, getters) => (hour) => {
        if (!getters.shouldDisplayMoon) {
            return false;
        }
        const moonHours = getters.getMoonHours(hour);
        if (moonHours) {
            const hourValue = moonHours[hour];
            // console.log(`hour = ${hour}; hourValue = ${hourValue.hour}; illuminated = ${hourValue.illuminated}; minutesVisible = ${hourValue.minutesVisible}`)
            if (hourValue) {
                return hourValue.minutesVisible > 0;
            }
        }
        return false;
    },

    // TODO rework so it works just with illuminated and phase ...
    // eslint-disable-next-line no-unused-vars
    getMoonImage: (state, getters) => (hour) => {
        const moonHours = getters.getMoonHours(hour);
        if (moonHours) {
            const hourValue = moonHours[hour];
            if (hourValue) {
                return MoonPhases.getImage(hourValue.illuminated, hourValue.phase);
            }
        }
        return 'moon_full_15.png'; // this must not happen ...
    },

    getCloudOptions: (state) => {
        return state.cloudOptions;
    },

    getDisplayedCloudType: (state) => {
        return state.displayedCloudType;
    },

    getLocation: (state) => {
        return state.location;
    },

    shouldCenterOnMidnight: () => {
        return state.centerOnMidnight;
    },

    shouldSortByReliability: () => {
        return state.sortByReliability;
    },

    getForecastAvgMode: () => {
        return state.forecastAvgMode;
    },

    getForecastReliabilityRadius: (state) => {
        return state.forecastReliabilityRadius;
    },

    getForecastReliabilityType: (state) => {
        return state.forecastReliabilityType;
    },

    getForecastReliabilityRadiusAsInt: () => {
        const r = new RegExp("\\d+");
        const radiusKm = r.exec(state.forecastReliabilityRadius);
        console.log("radiusKm = " + radiusKm);
        return radiusKm;
    },

    getNightReliabilityDays: () => {
        return state.nightReliabilityDays;
    },

    shouldDisplayMoon: () => {
        return state.displayMoon;
    },

    getForecastRqSentTs: () => {
        return state.forecastRqSentTs;
    },

    getNightFromHour: () => {
        return state.nightFromHour;
    },

    getNightToHour: () => {
        return state.nightToHour;
    },

    isForeignLocation() {
        if (state.countryCode) {
            return state.countryCode.toLowerCase() !== "cz";
        }
        return false;
    },

}


const mutations = {

    setLoading: (state, value) => {
        state.loading = value;
    },

    setLoadingForecasts: (state, value) => {
        state.loading = value;
        state.loadingForecasts = value;
    },

    setDisplayedDate: (state, value) => {
        state.displayedDate = value;
    },

    setForecasts: (state, value) => {
        state.forecasts = value;
    },

    // TODO probably not needed ... not used
    setDayMovement: (state) => {
        const diff = state.daysOffsetFromToday - state.daysOffsetFromTodayPrev;
        console.log("diff = " + diff)
        if (diff === 1) {
            state.dayMovement = "FORWARDS";
        } else if (diff === -1) {
            state.dayMovement = "BACKWARDS";
        } else {
            state.dayMovement = null;
        }
    },

    setDailyOverall: (state, value) => {
        state.dailyOverall = value;
    },

    setForecastsLink: (state, value) => {
        state.forecastsLink = value;
    },

    setReliabilities: (state, value) => {
        state.reliabilities = value;
    },

    setNightReliabilityCharts: (state, value) => {
        state.nightReliabilityCharts = value;
    },

    setForecastTrend: (state, value) => {
        state.forecastTrend = value;
    },

    setForecastStatus: (state, value) => {
        state.forecastStatus = value;
    },

    setRegionalForecast: (state, value) => {
        state.regionalForecast = value;
    },

    setMoon: (state, value) => {
        state.moon = value;
    },

    setSun: (state, value) => {
        state.sun = value;
    },

    setNight: (state, value) => {
        state.night = value;
    },

    setDailyChart: (state, value) => {
        state.dailyChart = value;
    },

    setDaysOffsetFromToday: (state, value) => {
        state.daysOffsetFromToday = value;
    },

    setDaysOffsetFromTodayPrev: (state, value) => {
        state.daysOffsetFromTodayPrev = value;
    },

    setPageTitle: (state, value) => {
        state.pageTitle = value;
    },

    setLatitude: (state, value) => {
        state.latitude = value;
    },

    setLongitude: (state, value) => {
        state.longitude = value;
    },

    setCountryCode: (state, value) => {
        state.countryCode = value;
    },

    setDisplayedCloudType: (state, value) => {
        state.displayedCloudType = value;
    },

    updateCloudOptionsEnabling: (state, enabledCloudTypes) => {
        if (enabledCloudTypes && enabledCloudTypes.length > 0) {
            for (let option of state.cloudOptions) {
                if (enabledCloudTypes.includes(option.value)) {
                    option.disabled = false;
                } else {
                    option.disabled = true;
                }
            }
        } else {
            console.warn("Received invalid enabledCloudTypes: " + enabledCloudTypes)
        }
    },

    setLocation: (state, value) => {
        state.location = value;
    },

    setHomeLocationDisplayed: (state, value) => {
        state.homeLocationDisplayed = value;
    },

    setCenterOnMidnight: (state, value) => {
        localStorage.setItem("centerOnMidnight", value)
        state.centerOnMidnight = value;
    },

    setSortByReliability: (state, value) => {
        localStorage.setItem("sortByReliability", value)
        state.sortByReliability = value;
    },

    setForecastAvgMode: (state, value) => {
        if (value) {
            localStorage.setItem("forecastAvgMode", value)
            state.forecastAvgMode = value;
        }
    },

    setForecastReliabilityRadius: (state, value) => {
        if (value) {
            localStorage.setItem("forecastReliabilityRadius", value)
            state.forecastReliabilityRadius = value;
        }
    },

    setForecastReliabilityType: (state, value) => {
        if (value) {
            localStorage.setItem("forecastReliabilityType", value)
            state.forecastReliabilityType = value;
        }
    },

    setNightReliabilityDays: (state, value) => {
        if (value) {
            localStorage.setItem("nightReliabilityDays", value)
            state.nightReliabilityDays = value;
        }
    },

    setNightFromHour: (state, value) => {
        if (value !== null) {
            localStorage.setItem("nightFromHour", value)
            state.nightFromHour = value;
        }
    },

    setNightToHour: (state, value) => {
        if (value !== null) {
            localStorage.setItem("nightToHour", value)
            state.nightToHour = value;
        }
    },

    setRegionalForecastTime: (state, value) => {
        if (value) {
            state.regionalForecastTime = value;
        }
    },

    setRegionalForecastTimeInitial: (state, regionalForecast) => {
        if (regionalForecast && regionalForecast.times.length > 0) {
            state.regionalForecastTime = regionalForecast.times[0];
        }
    },

    setDisplayMoon: (state, value) => {
        state.displayMoon = value;
    },

    setForecastRqSentTs: (state) => {
        state.forecastRqSentTs = Date.now();
    },

    setDisplayedDailyChartDiffType: (state, value) => {
        state.displayedDailyChartDiffType = value;
    },

    setPastHoursVerification: (state, value) => {
        state.pastHoursVerification = value;
    },
}


const actions = {

    reloadForecasts({getters, dispatch}) {
        console.log("reloading forecasts ...")
        if (getters.getLocation) {
            dispatch('loadForecasts', getters.getLocation);
        } else {
            dispatch('loadForecastsForWholeCountry');
        }
    },

    loadForecastsForWholeCountry({dispatch}) {
        dispatch('loadForecasts', null);
    },

    // eslint-disable-next-line no-unused-vars
    loadForecasts({commit, getters, rootGetters}, location) {
        const displayName = location !== null ? location.displayName : null;
        const lat = location !== null ? location.lat : null;
        const lon = location !== null ? location.lon : null;
        const countryCode = location !== null ? location.countryCode : null;
        if (location != null) {
            commit('setLoadingForecasts', true);
        } else {
            commit('setLoading', true);
        }
        commit('setLocation', location);
        commit('setForecastRqSentTs');
        const reliabilityRadius = getters.getForecastReliabilityRadiusAsInt;
        console.log("reliabilityRadius === " + reliabilityRadius)
        const days = getters.getForecastRequestDays.join(",");
        const nightReliabilityDays = getters.getNightReliabilityDays;
        const displayedSources = rootGetters["settingsStore/getDisplayedSources"];
        const nightFromHour = getters.getNightFromHour;
        const nightToHour = getters.getNightToHour;
        const reliabilityType = getters.getForecastReliabilityType; // TODO should be possible to set from the response also!
        ApiClient.fetchForecasts(false, displayName, lat, lon, countryCode, reliabilityRadius, days, nightReliabilityDays, displayedSources, nightFromHour, nightToHour, reliabilityType)
            .then(forecasts => {
                    if (forecasts) {
                        console.log("Received forecasts: " + forecasts)
                        commit('setDayMovement');
                        commit('setDailyOverall', forecasts.dailyOverall);
                        commit('setReliabilities', forecasts.reliabilities);
                        commit('setNightReliabilityCharts', forecasts.nightReliabilityCharts);
                        commit('setForecastTrend', forecasts.forecastTrend);
                        commit('setForecastStatus', forecasts.forecastStatus);
                        commit('setRegionalForecast', forecasts.regionalForecast);
                        commit('setRegionalForecastTimeInitial', forecasts.regionalForecast);
                        commit('setMoon', forecasts.moon);
                        commit('setSun', forecasts.sun);
                        commit('setNight', forecasts.night);
                        commit('setDailyChart', forecasts.dailyChart);
                        commit('setPageTitle', forecasts.title); // TODO remove
                        commit('setLatitude', forecasts.latitude); // TODO remove
                        commit('setLongitude', forecasts.longitude); // TODO remove
                        commit('setCountryCode', forecasts.countryCode); // TODO remove
                        commit('setLocation', forecasts.location);
                        if (forecasts.location) {
                            commit('setHomeLocationDisplayed', forecasts.location.homeLocation);
                        }
                        commit('updateCloudOptionsEnabling', forecasts.cloudTypes);
                        commit('setDisplayedDate', Utils.offsetDate(Utils.todayDate(), getters.getDaysOffsetFromToday));
                        commit('setForecasts', forecasts.dayForecasts);
                        commit('setPastHoursVerification', forecasts.pastHoursVerification);
                    } else {
                        console.log("Failed to receive forecasts")
                    }
                    commit('setLoadingForecasts', false);
                }
            );
    },

    loadForecastsForUuid({commit, getters, rootGetters}, uuid) {
        console.log("Will load forecasts by uuid: " + uuid);
        commit('setLoadingForecasts', true);
        const reliabilityRadius = getters.getForecastReliabilityRadiusAsInt;
        const dayCount = getters.shouldCenterOnMidnight ? 2 : 1;
        const nightReliabilityDays = getters.getNightReliabilityDays;
        const displayedSources = rootGetters["settingsStore/getDisplayedSources"];
        const nightFromHour = getters.getNightFromHour;
        const nightToHour = getters.getNightToHour;
        const reliabilityType = getters.getForecastReliabilityType; // TODO should be possible to set from the response also!
        ApiClient.fetchForecastsByUuid(uuid, reliabilityRadius, dayCount, nightReliabilityDays, displayedSources, nightFromHour, nightToHour, reliabilityType)
            .then(forecasts => {
                    if (forecasts) {
                        // no dayMovement here as we are loading from the link directly ...
                        console.log("Received forecasts for uuid: " + forecasts)
                        commit('setDailyOverall', forecasts.dailyOverall);
                        commit('setReliabilities', forecasts.reliabilities);
                        commit('setNightReliabilityCharts', forecasts.nightReliabilityCharts);
                        commit('setForecastTrend', forecasts.forecastTrend);
                        commit('setForecastStatus', forecasts.forecastStatus);
                        commit('setRegionalForecast', forecasts.regionalForecast);
                        commit('setRegionalForecastTimeInitial', forecasts.regionalForecast);
                        commit('setMoon', forecasts.moon);
                        commit('setSun', forecasts.sun);
                        commit('setNight', forecasts.night);
                        commit('setPageTitle', forecasts.title); // TODO remove
                        commit('setDailyChart', forecasts.dailyChart);
                        commit('setLatitude', forecasts.latitude); // TODO remove
                        commit('setLongitude', forecasts.longitude); // TODO remove
                        if (forecasts.location) {
                            commit('setLocation', forecasts.location);
                            commit('setHomeLocationDisplayed', forecasts.location.homeLocation);
                        } else {
                            commit('setLocation', {displayName: forecasts.title, lat: forecasts.latitude, lon: forecasts.longitude, countryCode: forecasts.countryCode}); // TODO remove
                        }
                        commit('updateCloudOptionsEnabling', forecasts.cloudTypes);
                        commit('setDaysOffsetFromToday', forecasts.day - 1); // TODO maybe rename to display day?
                        commit('setDisplayedDate', Utils.offsetDate(Utils.todayDate(), getters.getDaysOffsetFromToday));
                        commit('setForecasts', forecasts.dayForecasts);
                        commit('setPastHoursVerification', forecasts.pastHoursVerification);
                    } else {
                        console.log("Failed to receive forecasts by uuid")
                    }
                    commit('setLoadingForecasts', false);
                }
            );
    },

    // eslint-disable-next-line no-unused-vars
    loadPartialForecastsUpdate({commit, getters, rootGetters}, location) {
        const displayName = location !== null ? location.displayName : null;
        const lat = location !== null ? location.lat : null;
        const lon = location !== null ? location.lon : null;
        const countryCode = location !== null ? location.countryCode : null;
        commit('setLoading', true);
        commit('setLocation', location);
        commit('setForecastRqSentTs');
        const reliabilityRadius = getters.getForecastReliabilityRadiusAsInt;
        console.log("reliabilityRadius === " + reliabilityRadius)
        const days = getters.getForecastRequestDays.join(",");
        const nightReliabilityDays = getters.getNightReliabilityDays;
        const displayedSources = rootGetters["settingsStore/getDisplayedSources"];
        const nightFromHour = getters.getNightFromHour;
        const nightToHour = getters.getNightToHour;
        const reliabilityType = getters.getForecastReliabilityType; // TODO should be possible to set from the response also!
        ApiClient.fetchForecasts(true, displayName, lat, lon, countryCode, reliabilityRadius, days, nightReliabilityDays, displayedSources, nightFromHour, nightToHour, reliabilityType)
            .then(forecasts => {
                    if (forecasts) {
                        console.log("Received forecasts: " + forecasts)
                        if (forecasts.reliabilities) {
                            commit('setReliabilities', forecasts.reliabilities);
                        }
                        if (forecasts.forecastTrend) {
                            commit('setForecastTrend', forecasts.forecastTrend);
                        }
                        if (forecasts.forecastStatus) {
                            commit('setForecastStatus', forecasts.forecastStatus);
                        }
                        if (forecasts.pastHoursVerification) {
                            commit('setPastHoursVerification', forecasts.pastHoursVerification);
                        }
                    } else {
                        console.log("Failed to receive partial forecasts update")
                    }
                    commit('setLoading', false);
                }
            );
    },

    // eslint-disable-next-line no-unused-vars
    saveHomeLocation({commit, getters, rootGetters}) {
        console.log("Inside saveHomeLocation")
        if (getters.getLocation) {
            const location = getters.getLocation;
            const displayName = location !== null ? location.displayName : null;
            const lat = location !== null ? location.lat : null;
            const lon = location !== null ? location.lon : null;
            const countryCode = location !== null ? location.countryCode : null;
            ApiClient.saveHomeLocation(displayName, lat, lon, countryCode)
                .then(response => {
                        if (response.ok) {
                            commit('setHomeLocationDisplayed', true)
                        } else {
                            commit('setHomeLocationDisplayed', false)
                            console.log("Failed to save home location")
                        }
                    }
                )
        } else {
            // forces an update to propagate to the UI control (button)
            commit('setHomeLocationDisplayed', true)
            commit('setHomeLocationDisplayed', false)
        }
    },

    deleteHomeLocation({commit}) {
        console.log("Inside deleteHomeLocation")
        ApiClient.deleteHomeLocation()
            .then(response => {
                    if (response.ok) {
                        commit('setHomeLocationDisplayed', false)
                    } else {
                        commit('setHomeLocationDisplayed', true)
                        console.log("Failed to delete home location")
                    }
                }
            )
    },

    reloadPartialForecastsUpdate({getters, dispatch}) {
        if (!getters.isLoading) {
            console.log("reloading partial forecast update ...")
            if (getters.getLocation) {
                dispatch('loadPartialForecastsUpdate', getters.getLocation);
            } else {
                dispatch('loadPartialForecastsUpdate', null);
            }
        } else {
            console.log("Already loading - partial forecast update request")
        }
    },

    reloadReliabilities({getters, dispatch}) {
        if (!getters.isLoading) {
            console.log("reloading reliabilities ...")
            if (getters.getLocation) {
                dispatch('loadReliabilities', getters.getLocation);
            } else {
                dispatch('loadReliabilities', null);
            }
        } else {
            console.log("Already loading - ignoring reliability reload request")
        }
    },

    // eslint-disable-next-line no-unused-vars
    loadReliabilities({commit, getters}, location) {
        console.log("reloading reliabilities for radius: " + getters.getForecastReliabilityRadiusAsInt)
        const displayName = location !== null ? location.displayName : null;
        const lat = location !== null ? location.lat : null;
        const lon = location !== null ? location.lon : null;
        const countryCode = location !== null ? location.countryCode : null;
        commit('setLoading', true);
        const reliabilityRadius = getters.getForecastReliabilityRadiusAsInt;
        const reliabilityType = getters.getForecastReliabilityType;
        ApiClient.fetchReliabilities(displayName, lat, lon, countryCode, reliabilityRadius, reliabilityType)
            .then(reliabilities => {
                    if (reliabilities) {
                        console.log("Received reliabilities: " + reliabilities)
                        commit('setReliabilities', reliabilities);
                    } else {
                        console.log("Failed to receive reliabilities")
                    }
                    commit('setLoading', false);
                }
            );
    },

    loadNightReliabilities({commit, getters}, location) {
        console.log("reloading night reliabilities")
        const displayName = location !== null ? location.displayName : null;
        const lat = location !== null ? location.lat : null;
        const lon = location !== null ? location.lon : null;
        commit('setLoading', true); // TODO do not show mesages ...
        const nightReliabilityDays = getters.getNightReliabilityDays;
        const reliabilityRadius = getters.getForecastReliabilityRadiusAsInt;
        ApiClient.fetchNightReliabilities(displayName, lat, lon, reliabilityRadius, nightReliabilityDays)
            .then(reliabilities => {
                    if (reliabilities) {
                        console.log("Received night reliabilities: " + reliabilities)
                        commit('setNightReliabilityCharts', reliabilities);
                    } else {
                        commit('setNightReliabilityCharts', null);
                        console.log("Failed to receive night reliabilities")
                    }
                    commit('setLoading', false);
                }
            );
    },


    fetchForecastsLink({commit, getters}) {
        console.log("fetching forecasts link ...")
        const location = getters.getLocation;
        const displayName = location !== null ? location.displayName : null;
        const lat = location !== null ? location.lat : null;
        const lon = location !== null ? location.lon : null;
        const countryCode = location !== null ? location.countryCode : null;
        commit('setLoading', true);
        const displayedDayIdx = state.daysOffsetFromToday + 1;
        ApiClient.fetchForecastsLink(displayName, lat, lon, countryCode, displayedDayIdx)
            .then(link => {
                    if (link) {
                        console.log("Received forecasts link: " + link)
                        commit('setForecastsLink', link);
                    } else {
                        console.log("Failed to receive forecasts link")
                    }
                    commit('setLoading', false);
                }
            );
    },

    changeDisplayedCloudType({commit}, type) {
        commit('setDisplayedCloudType', type);
    },

    // eslint-disable-next-line no-unused-vars
    toggleCenterOnMidnight: ({commit, getters, dispatch}, value) => {
        commit('setCenterOnMidnight', value);
        dispatch('reloadForecasts'); // more days displayed - need to reload or moon data need recalculation
    },

    toggleSortByReliability: ({commit}, value) => {
        commit('setSortByReliability', value);
    },

    changeForecastAvgMode: ({commit}, value) => {
        commit('setForecastAvgMode', value);
    },

    changeForecastReliabilityRadius: ({commit}, value) => {
        // console.log("Inside changeForecastReliabilityRadius to " + value)
        commit('setForecastReliabilityRadius', value);
    },

    changeForecastReliabilityType: ({commit}, value) => {
        // console.log("Inside changeForecastReliabilityRadius to " + value)
        commit('setForecastReliabilityType', value);
    },

    changeNightReliabilityDays: ({commit, getters, dispatch}, value) => {
        // console.log("Inside changeNightReliabilityDays")
        commit('setNightReliabilityDays', value);
        dispatch('loadNightReliabilities', getters.getLocation);
    },

    changeRegionalForecastTime: ({commit}, value) => {
        // console.log("Inside changeRegionalForecastTime")
        commit('setRegionalForecastTime', value);
    },

    toggleDisplayMoon: ({commit, getters}) => {
        // console.log("Inside toggleDisplayMoon")
        commit('setDisplayMoon', !getters.shouldDisplayMoon);
    },

    // eslint-disable-next-line no-unused-vars
    changeDaysOffsetFromToday: ({commit, getters, dispatch}, value) => {
        commit('setDaysOffsetFromTodayPrev', getters.getDaysOffsetFromToday);
        commit('setDaysOffsetFromToday', value);
        dispatch('reloadForecasts');
    },

    changeNightFromHour: ({commit}, value) => {
        commit('setNightFromHour', value);
    },

    changeNightToHour: ({commit}, value) => {
        commit('setNightToHour', value);
    },

    toggleDisplayedDailyChartDiffType: ({commit}, option) => {
        commit('setDisplayedDailyChartDiffType', option);
    },

}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
