<template>

    <div class="container page-container">

        <h2 style="padding-top: 30px;text-align: center">Aktuality na SkyAccuracy.cz</h2>
        <hr>

        <h6>2024-08-23, změny v sekci Srovnání předpovědí:</h6>
        <ul>
            <li>Přidána možnost nastavit vyhledanou polohu jako domovskou.
                Volba je dostupná v kontextovém nastavení předpovědí a je platná pro použité zařízení.</li>
        </ul>

        <h6>2024-08-18, změny v sekci Srovnání předpovědí:</h6>
        <ul>
            <li>Nové popup menu s užitečnými odkazy na jiné weby o počasí</li>
            <li>Nový graf ukazující spolehlivost předpovědí oblačné oblohy pro X posledních nocí</li>
            <li>Ikona Slunce nyní ukazuje aktuální sluneční skvrny</li>
            <li>Legenda pro seeing a průzračnost vylepšena o číselné vyjádření stupnice spolu se slovním popisem</li>
        </ul>

        <h6>2024-08-10, změny v sekci Srovnání předpovědí:</h6>
        <ul>
            <li>Přidána animace stmívající se oblohy</li>
        </ul>

        <h6>2024-08-01, změny v sekci Srovnání předpovědí:</h6>
        <ul>
            <li>Zrychlení načítání úvodní stránky s předpovědí pro Českou republiku</li>
        </ul>

    </div>

</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: "Changelog",
    mounted () {
        window.scrollTo(0, 0)
    },
    data() {
        return {
            showTooltip: false // workaround for clicked locations to lose focus and the tooltip to be hidden
        }
    },
    computed: {
        ...mapGetters([])
    },
    methods: {
    }
}

</script>

<style scoped>
.small-header {
    padding-top: 10px;
    text-align: center
}

.source {
    font-weight: 600;
}

.usage {
    font-weight: 500;
}

/*.tooltip {*/
/*    position: absolute;*/
/*    background-color: black;*/
/*    color: white;*/
/*    padding: 5px;*/
/*    border-radius: 5px;*/
/*    opacity: 0;*/
/*    transition: opacity 0.3s ease-in-out;*/
/*}*/
</style>
