<template>

    <div style="margin-top: 40px; margin-bottom: 15px">

        <h6 style="text-align: center; font-size: 22px; font-weight: 600;">
            Kdo umí předpovědět noční oblohu?
        </h6>

        <div class="row" style="margin-top: 27px">
            <div class="col-sm-6">
                <h6 class="centered" style="margin-bottom: -10px">Předpovědi jasné oblohy</h6>
                <NightReliabilityChart :chart-type="NightReliabilityTypes.CLEAR_SKY" style="margin-bottom: 25px"></NightReliabilityChart>

                <h6 class="centered" style="margin-bottom: -10px">Předpovědi oblačné oblohy</h6>
                <NightReliabilityChart :chart-type="NightReliabilityTypes.CLOUDY_SKY" style="margin-bottom: 15px"></NightReliabilityChart>
            </div>
            <div class="col-sm-6 d-flex align-items-center">
                <div>
<!--                    <NightReliabilityChartSlider v-bind:initial-value="getNightReliabilityDays" style="margin-bottom: 25px"></NightReliabilityChartSlider>-->
                    <NightReliabilityChartSlider style="margin-bottom: 25px"></NightReliabilityChartSlider>

                    <p>Jak moc je možné důvěřovat modelu, který předpovídá jasnou oblohu? A jak spolehlivé jsou modely při předpovědích oblačné nebo zcela zatažené oblohy?</p>

                    <p>Grafy zobrazují úspěšnost v předpovídání jasné a oblačné noční oblohy na základě porovnání hodinových předpovědí
                        se skutečným změřeným stavem oblačnosti.
                        Úspěšnost je počítána za celý vybraný časový úsek a vychází z dat dostupných pro <span style="font-weight: 600">širší region vyhledané lokality</span>.
                    </p>

                    <Tip text="Velikost širšího regionu lze nastavit."></Tip>

                </div>
            </div>
        </div>
        <div>
        </div>

    </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {ForecastColors} from "@/config/forecast-colors";
import {Sources} from "@/config/sources";
import NightReliabilityChart from "@/components/forecast/NightReliabilityChart";
import NightReliabilityChartSlider from "@/components/forecast/NightReliabilityChartSlider";
import Tip from "@/components/Tip.vue";
import {NightReliabilityTypes} from "@/utils/constants";

export default {
    name: "NightReliability",
    computed: {
        NightReliabilityTypes() {
            return NightReliabilityTypes
        },
        ...mapGetters('forecastsStore', ['getNightReliabilityDays']),
        ...mapGetters('settingsStore', ['getDisplayedSources']),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        // getForecastTrend: function (newValue) {
        // },
    },
    data() {
        return {
            trendPopupDisplayed: false,
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
        getShadesOfReliability() {
            return  [...ForecastColors.shadesOfReliability].reverse();
        },
        getShadeStyle(shade) {
            return "text-align: center; backgroundColor: " + shade;
        },
        getSourceName(sourceCode) {
            return Sources.displayName(sourceCode);
        }
    },
    components: {
        Tip,
        // eslint-disable-next-line vue/no-unused-components
        NightReliabilityChart, NightReliabilityChartSlider
    }
}
</script>

<style scoped>

.table tbody tr td:first-child {
    vertical-align: middle;
    text-align: left;
    font-weight: 600;
}

</style>
