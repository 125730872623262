<template>
    <p class="trend-desc-text">Graf zachycuje změny souhrnné přepovědi oblačnosti na noc v průběhu posledních
        {{ getForecastTrend.trendIntervalHours }} hodin. Hodnoty jsou průměrem dat
        z dostupných modelů pro <span style="font-weight: 600">širší region vyhledané lokality</span>,
        aby postihly obecný trend.
    </p>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
    name: "ForecastTrend",
    computed: {
        ...mapGetters('forecastsStore', ['getForecastTrend']),
    },
    watch: {},
    data() {
        return {
            trendPopupDisplayed: false,
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
    },
    components: {}
}
</script>

<style scoped>

</style>
