// noinspection JSNonASCIINames
export class Locations {

    // noinspection NonAsciiCharacters
    static locationsByCounty = {
        "Hlavní město Praha": ["Praha-Kbely", "Praha-Libuš", "Praha-Karlov", "Praha-Ruzyně"],
        "Jihomoravský kraj": ["Kuchařovice", "Brno-Tuřany"],
        "Jihočeský kraj": ["České Budějovice", "Temelín", "Kocelovice", "Churáňov"],
        "Karlovarský kraj": ["Karlovy Vary", "Cheb"],
        "Kraj Vysočina": ["Dukovany", "Náměšť nad Oslavou", "Košetice", "Kostelní Myslová", "Přibyslav"],
        "Královéhradecký kraj": ["Pec pod Sněžkou", "Polom", "Jičín"],
        "Liberecký kraj": ["Liberec"],
        "Moravskoslezský kraj": ["Červená u Libavé", "Ostrava-Mošnov", "Lysá hora"],
        "Olomoucký kraj": ["Prostějov", "Luká", "Šerák"],
        "Pardubický kraj": ["Ústí nad Orlicí", "Pardubice", "Svratouch"],
        "Plzeňský kraj": ["Přimda", "Plzeň-Mikulka"],
        "Středočeský kraj": ["Čáslav"],
        "Zlínský kraj": ["Maruška", "Holešov"],
        "Ústecký kraj": ["Ústí nad Labem", "Kopisty", "Doksany", "Milešovka", "Tušimice"],
    }

    // noinspection NonAsciiCharacters
    static countyByLocation = {
        "Praha-Kbely": "Hlavní město Praha",
        "Praha-Libuš": "Hlavní město Praha",
        "Praha-Karlov": "Hlavní město Praha",
        "Praha-Ruzyně": "Hlavní město Praha",
        "Kuchařovice": "Jihomoravský kraj",
        "Brno-Tuřany": "Jihomoravský kraj",
        "České Budějovice": "Jihočeský kraj",
        "Temelín": "Jihočeský kraj",
        "Kocelovice": "Jihočeský kraj",
        "Churáňov": "Jihočeský kraj",
        "Karlovy Vary": "Karlovarský kraj",
        "Cheb": "Karlovarský kraj",
        "Dukovany": "Kraj Vysočina",
        "Náměšť nad Oslavou": "Kraj Vysočina",
        "Košetice": "Kraj Vysočina",
        "Kostelní Myslová": "Kraj Vysočina",
        "Přibyslav": "Kraj Vysočina",
        "Pec pod Sněžkou": "Královéhradecký kraj",
        "Polom": "Královéhradecký kraj",
        "Jičín": "Královéhradecký kraj",
        "Liberec": "Liberecký kraj",
        "Červená u Libavé": "Moravskoslezský kraj",
        "Ostrava-Mošnov": "Moravskoslezský kraj",
        "Lysá hora": "Moravskoslezský kraj",
        "Prostějov": "Olomoucký kraj",
        "Luká": "Olomoucký kraj",
        "Šerák": "Olomoucký kraj",
        "Ústí nad Orlicí": "Pardubický kraj",
        "Pardubice": "Pardubický kraj",
        "Svratouch": "Pardubický kraj",
        "Přimda": "Plzeňský kraj",
        "Plzeň-Mikulka": "Plzeňský kraj",
        "Čáslav": "Středočeský kraj",
        "Maruška": "Zlínský kraj",
        "Holešov": "Zlínský kraj",
        "Ústí nad Labem": "Ústecký kraj",
        "Kopisty": "Ústecký kraj",
        "Doksany": "Ústecký kraj",
        "Milešovka": "Ústecký kraj",
        "Tušimice": "Ústecký kraj",
    }

    static countyById = {
        JC: "Jihočeský kraj",
        JM: "Jihomoravský kraj",
        KA: "Karlovarský kraj",
        KR: "Královéhradecký kraj",
        LI: "Liberecký kraj",
        MO: "Moravskoslezský kraj",
        OL: "Olomoucký kraj",
        PA: "Pardubický kraj",
        ZL: "Zlínský kraj",
        PL: "Plzeňský kraj",
        PR: "Hlavní město Praha",
        ST: "Středočeský kraj",
        US: "Ústecký kraj",
        VY: "Kraj Vysočina"
    }

    static getLocationsByCounty(county) {
        return (Locations.locationsByCounty)[county];
    }

    static getCountyByLocation(location) {
        return (Locations.countyByLocation)[location];
    }

    static getCountyById(id) {
        return Locations.countyById[id];
    }

    static locationsAreCompleteForCounty(county, locations) {
        let countyLocations = Locations.getLocationsByCounty(county);
        let notIncluded = 0;
        for (const location of countyLocations) {
            if (!locations.includes(location)) {
                notIncluded++;
            }
        }
        return notIncluded <= 0;

    }

}
