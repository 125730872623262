<template>

        <div v-if="getForecastTrend || getForecastStatus">
                <div style="margin-bottom: 15px" class="d-flex justify-content-center align-items-center">
                    <div class="trend-desc" :style="getStyle()">
                    <span style="margin-right: 8px">
                        {{getForecastStatus.description}}
                    </span>
                        <b-icon-info-circle size="sm" variant="info" style="margin-bottom: 2px; cursor: pointer;" v-on:click="toggleTrendPopup()"/>
                    </div>

                    <div class="overlay" v-if="trendPopupDisplayed" @click="toggleTrendPopup"></div>
                    <div class="overlay" v-if="reliabilityPopupDisplayed" @click="toggleReliabilityPopup"></div>

                    <Transition>
                        <div class="popup" v-if="trendPopupDisplayed">
                            <div class="ml-auto mr-3" v-on:click="toggleTrendPopup()">
                                <span class="popup-close">&times;</span>
                            </div>
                            <div style="text-align: center; margin-bottom: 20px">
                                <h4 class="popup-title">Jak se mění předpovědi na noc</h4>
                                <h4 class="trend-popup-subtitle">{{this.getDate()}}</h4>
                                <hr>
                                <p class="popup-text">Graf zachycuje změny předpovědí oblačnosti za posledních
                                    {{getForecastTrend.trendIntervalHours}} hodin pro <span style="font-weight: 600">širší region vyhledané lokality</span>.
                                    Hodnoty jsou průměrované z dat dostupných modelů, aby postihly obecný trend.
                                </p>
                            </div>
                            <ForecastTrendChart></ForecastTrendChart>
                        </div>
                    </Transition>

                    <Transition>
                        <div class="popup" v-if="reliabilityPopupDisplayed">
                            <div class="ml-auto mr-3" v-on:click="toggleReliabilityPopup()">
                                <span class="popup-close">&times;</span>
                            </div>
                            <div style="text-align: center; margin-bottom: 20px">
                                <h4 class="popup-title">Aktuální přesnost předpovědí</h4>
                                <hr>
                                <p>
                                    V posledních hodinách se reálný stav oblačnosti výrazněji odchýlil od předpovědí většiny modelů.
                                </p>
                                <p style="font-weight: 500" v-if="getLastUpdate">
                                    Aktualizováno: {{getLastUpdate()}}
                                </p>
                                <hr>
                                <p class="small-text">
                                    Barevné "karty" u jména modelu jsou indikátorem jeho přesnosti za vámi zvolených posledních několik hodin.
                                    Do vyhodnocení přesnosti pak vstupují desítky až stovky měření reálné úrovně oblačnosti
                                    z širšího regionu vyhledané lokality dle vašeho nastavení.
                                </p>
                            </div>
                            <ForecastReliabilityScaleLegend class="small-text"></ForecastReliabilityScaleLegend>
                        </div>
                    </Transition>
                </div>
        </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {BIconInfoCircle} from "bootstrap-vue";
import ForecastTrendChart from "@/components/forecast/ForecastTrendChart";
import {Utils} from "@/utils/Utils";
import ForecastReliabilityScaleLegend from "@/components/forecast/ForecastReliabilityScaleLegend.vue";

export default {
    name: "ForecastStatusPopup",
    computed: {
        ...mapGetters('forecastsStore', ['getForecastStatus', 'getForecastTrend',
            'getForecastStatusType', 'getPageTitle', 'todayForecastDisplayed']),
    },
    watch: {
        // eslint-disable-next-line no-unused-vars
        // getForecastTrend: function (newValue) {
        // },
    },
    data() {
        return {
            trendPopupDisplayed: false,
            reliabilityPopupDisplayed: false,
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
        getStyle() {
            const color = this.getForecastStatus.color;
            if (color === 'RED') {
                return "color: rgb(236 89 89); background-color: rgb(255 233 233)";
            } else if (color === 'ORANGE') {
                return "color: rgb(253 154 27); background-color: rgb(253 237 205)";
            } else if (color === 'GREEN') {
                return "color: #4ba74b; background-color: rgb(233 247 218)";
            } else if (color === 'GREY') {
                return "color: grey; background-color: rgb(245, 245, 245)";
            }
        },
        toggleTrendPopup() {
            if (this.getForecastStatusType === 'RELIABILITY_ALERT') {
                this.reliabilityPopupDisplayed = !this.reliabilityPopupDisplayed;
            } else {
                this.trendPopupDisplayed = !this.trendPopupDisplayed;
            }
        },
        toggleReliabilityPopup() {
            this.reliabilityPopupDisplayed = !this.reliabilityPopupDisplayed;
        },
        getDate() {
            return Utils.formatDate(new Date(this.getForecastTrend.date), true, false, true);
        },
        getLastUpdate() {
            if (this.getForecastStatus.lastUpdate) {
                return Utils.formatToTime_HHmm(new Date(this.getForecastStatus.lastUpdate));
            }
            return null;
        },
    },
    components: {
        ForecastReliabilityScaleLegend,
        BIconInfoCircle, ForecastTrendChart
    }
}
</script>

<style scoped>

/*Must precede media queries*/
.popup-title {
    font-size: medium;
}

.trend-popup-subtitle {
    color: #000078;
    font-size: medium;
}


.trend-desc {
    padding: 4px 17px;
    text-align: center;
    border-radius: 4px;
}

@media (max-width: 370px) {
    .trend-desc {
        font-size: 13px;
    }
    .trend-popup-subtitle {
        color: #000078;
        font-size: small;
    }
}

@media (min-width: 371px) and (max-width: 699px) {
    .trend-desc {
        font-size: 13px;
        min-width: 300px;
    }
}

@media (min-width: 700px) {
    .trend-desc {
        font-size: 14px;
        min-width: 330px;
    }
}

</style>
