<template>
    <div>
        <div>
            <canvas ref="forecastTrendCanvas"></canvas>
        </div>
    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import Chart from 'chart.js'
import {mapGetters} from "vuex";
import {Utils} from "@/utils/Utils";
import {reactiveProp} from "vue-chartjs/es/mixins";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

export default {
    name: "ForecastTrendChart",
    props: {
        initialRefresh: null,
    },
    computed: {
        ...mapGetters('forecastsStore', ['getForecastTrend']),
    },
    watch: {
        getForecastTrend: function () {
            console.log("Inside watch for getForecastTrend")
            this.refreshData();
        },
    },
    data() {
        return {
            selected: 'diff',
            chart: null,
            datacollection: null,
            options: {
                layout: {
                    padding: {
                        left: 5,
                        right: 25,
                        top: 30,
                        bottom: 5
                    },
                },
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 1.5,
                legend: {
                    display: false,
                    position: 'bottom',
                    labels : {
                        usePointStyle: true,
                        padding: 5
                    }
                },
                scales: {
                    xAxes: [{
                        display: true,
                        type: 'time',
                        time: {
                            unit: 'day',
                        },
                        scaleLabel: {
                            display: true,
                            fontSize: 13,
                            fontColor: 'rgb(5,58,140)',
                            padding: {
                                top: 2,
                            },
                            labelString: "Čas předpovědí",
                        },
                        gridLines: {
                            // zeroLineWidth:1.5,
                            zeroLineColor: 'rgba(0, 0, 0, 0)', // transparent so it is not visible ...
                        },
                        ticks: {
                            padding: 10,
                            fontColor: 'rgb(77,77,77)',
                            fontSize: 9,
                            maxTicksLimit: 5,
                            maxRotation: 90,
                            display: false, // HIDES THE X LABELS
                            // callback: (value) => {
                            //     console.log("HERE ... value: " + value.replace(" ", ""))
                            //     const tick = this.xTickValues.find((tick) => {
                            //         console.log("tick.value = " + tick.value)
                            //         return value.replace(" ", "").indexOf(tick.value) !== -1;
                            //     });
                            //     console.log("HERE ...: " + tick)
                            //     console.dir(tick)
                            //     console.dir(this.xTickValues)
                            //     console.log("tick !== undefined: " + (tick !== undefined))
                            //     const result = tick !== undefined ? tick.label : null;
                            //     console.log("result = " + result)
                            //     return result;
                            // },
                        },
                        labels: []
                    }],
                    yAxes: [{
                        min: 0,    // Set the minimum value to 0
                        max: 100,  // Set the maximum value to 100
                        display: true,
                        scaleLabel: {
                            display: true,
                            fontColor: 'rgb(5,58,140)',
                            fontSize: 13,
                            labelString: '% oblačnosti (Ø předpovědí)',
                            padding: {
                                right: 3,
                            },
                        },
                        beginAtZero: true,
                        ticks: {
                            suggestedMin: 0,
                            suggestedMax: 100,
                            fontSize: 11,
                            fontColor: 'rgb(77,77,77)',
                            maxTicksLimit: 6,
                            padding: 4
                        },
                        gridLines: {
                            zeroLineColor: 'rgba(0, 0, 0, 0)'
                        },
                    }]
                },
                plugins: { // global options for chart ...
                    datalabels: {
                        font: {
                            size: 9,
                            // weight: 700
                        },
                        // color: 'rgb(100,163,229)',
                        align: 'top',
                        anchor: 'end',
                        offset: 2,
                        // eslint-disable-next-line no-unused-vars
                        formatter: function (value, context) {
                            return Utils.formatToTime_ddMM_break_HHmm(new Date(value.x));
                        }
                    }
                },
                elements: {
                    point: {
                        radius: 2.5,
                        hoverRadius: 6,
                    }
                }
            }
        }
    },
    methods: {
        refreshData() {
            console.log("refreshData ...")
            if (!this.getForecastTrend) {
                console.log("No data for trend");
                return;
            }
            this.datacollection = {};
            const values = this.getForecastTrend.values;
            const data = Object.keys(values).map(dateTime => {
                return {
                    x: dateTime,
                    y: values[dateTime]
                }
            });

            const trend = this.getForecastTrend.trend;
            const borderColor = trend === 'WORSE' ? 'rgb(243,139,139)'
                : trend === 'UNSTABLE' ? 'rgb(253,154,27)'
                : trend === 'BETTER' ? 'rgb(196,236,152)'
                    : 'rgba(127,127,128,0.66)';

            // TODO anywhere else I can put this ?
            this.datacollection.datasets = [{
                // hidden: true,
                    backgroundColor: 'rgba(117,129,152,0.3)',
                    borderColor: borderColor,
                    borderWidth: 1,
                    data: data
            }];

            const labels = Object.keys(values).map(dateTime => {
                const time =  dateTime.replace("T", " ") + ":00";
                return time;
            });

            // has to have the same format as the tick date times ...
            this.xTickValues = Object.keys(values).map(dateTime => {
                const dt = new Date(dateTime);
                const date = Utils.toDate_Md(dt);
                const val = date + "," + dt.getHours();
                return {label: date, value: val} ;
            });

            this.datacollection.labels = labels;
            this.options.scales.xAxes[0].labels = labels

            this.createCustomChart();
        },
        createCustomChart() {
            if (this.chart) {
                this.chart.destroy(); // Destroy the previous chart if it exists
            }

            const ctx = this.$refs.forecastTrendCanvas.getContext("2d");
            this.chart = new Chart(ctx, {
                type: 'line',
                mixins: [reactiveProp],
                data: this.datacollection,
                options: this.options,
                plugins: [ChartJsPluginDataLabels]
            });
        },
    },
    mounted() {
        // console.log("Mounting ForecastTrendChart");
        this.refreshData();
    },
    components: {
    }
}
</script>

<style>
</style>
