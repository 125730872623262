<template>

    <div class="row" style="margin-top: 40px">
        <div class="col-12">
            <h6 style="margin-top: 10px; text-align: center; font-size: 22px; font-weight: 600;">
                Legenda
            </h6>

            <div v-if="!isForeignLocation">
                <h6 style="margin-top: 35px">Aktuální přesnost modelů</h6>

                <p>
                    Barevné "karty" u jména modelu jsou indikátorem jeho přesnosti za vámi zvolených posledních několik hodin.
                    Do vyhodnocení přesnosti pak vstupují desítky až stovky měření reálné úrovně oblačnosti
                    z širšího regionu vyhledané lokality dle vašeho nastavení.
                </p>

                <Tip text="Lze nastavit, z kolika posledních hodin bude aktuální přesnost počítána."></Tip>

                <p style="margin-top: 20px">
                    Pro více informací o přesnosti modelů oblačnosti navštivte sekci
                    <router-link to="/spolehlivost">o spolehlivosti</router-link>.
                </p>

                <ForecastReliabilityScaleLegend></ForecastReliabilityScaleLegend>
            </div>

            <h6 style="margin-top: 20px">Škála seeingu</h6>

            <table style="background-color: white; margin-bottom: 10px">
                <tr>
                    <td v-for="no in 8" v-bind:key="no" class="color-palette">
                        <SkyQuality :value="no" mode="seeing" style="margin-right: 10px; margin-left: 10px"></SkyQuality>
                    </td>
                </tr>
                <tr>
                    <td class="color-palette">&lt;0.5"</td>
                    <td class="color-palette">&lt;0.75"</td>
                    <td class="color-palette">&lt;1"</td>
                    <td class="color-palette">&lt;1.25"</td>
                    <td class="color-palette">&lt;1.5"</td>
                    <td class="color-palette">&lt;2"</td>
                    <td class="color-palette">&lt;2.5"</td>
                    <td class="color-palette">&gt;2.5"</td>
                </tr>
            </table>

            <p style="margin-bottom: 20px">
                Škála od nejlepší po nejhorší. Jednotkou je standardně úhlová sekunda.
            </p>


            <h6 style="margin-top: 20px">Škála průzračnosti</h6>

            <table style="background-color: white; margin-bottom: 10px">

                <tr>
                    <td v-for="no in 8" v-bind:key="no" class="color-palette" style="width: 25px; height: 25px;">
                        <SkyQuality :value="no" mode="transparency" style="margin-right: 10px; margin-left: 10px"></SkyQuality>
                    </td>
                </tr>
                <tr>
                    <td class="color-palette">&lt;0.3</td>
                    <td class="color-palette">&lt;0.4</td>
                    <td class="color-palette">&lt;0.5</td>
                    <td class="color-palette">&lt;0.6</td>
                    <td class="color-palette">&lt;0.7</td>
                    <td class="color-palette">&lt;0.85</td>
                    <td class="color-palette">&lt;1</td>
                    <td class="color-palette">&gt;1</td>
                </tr>
            </table>

            <p style="margin-bottom: 20px">
                Škála od nejlepší po nejhorší. Jednotkou je "mag per air mass", což představuje stupeň ztráty jasu
                na standardní astronomické stupnici magnitudy na "vzduchovou hmotu", kterou světlo projde. Při pohledu zpříma vzhůru prochází světlo jednou "vzduchovou hmotou". V úhlu 30° nad obzorem prochází světlo přibližně dvěma "vzduchovými hmotami".
            </p>

            <h6 style="margin-top: 20px">Optická hloubka aerosolů</h6>

            <p>
                Optická hloubka aerosolů je míra množství částic v atmosféře, které rozptylují světlo a ovlivňují průzračnost noční oblohy.
                Hodnoty 0.1 a méně jsou výborné, zatímco hodnoty nad 0.5 již mají značný negativní vliv na dobrou viditelnost objektů.
                Článek <a href="https://skyandtelescope.org/astronomy-resources/transparency-and-atmospheric-extinction/" target=”_blank” rel="noreferrer">
                Transparency and Atmospheric Extinction
                </a> od Sky&Telescope poskytuje více zajímavých informací o tomoto tématu.
            </p>

            <h6 style="margin-top: 20px">Slunce, Měsíc a souhvězdí</h6>
            <p>
                V řádku o Slunci a Měsíci se zobrazují také některá souhvězdí v hodinu, kdy jsou na obloze vidět
                přibližně nejvýše. Pokud je zobrazován v konkrétní hodině disk Měsíce,
                hvězdy nepředstavují souhvězdí ale jen náhodné hvězdy. Umístění Měsíce v řádku vystihuje, kdy vychází a
                zapadá.
            </p>

            <h6 style="margin-top: 20px">Zobrazované předpovědi oblačnosti</h6>
            <p>
                Pokud nechcete zobrazovat data o oblačnosti z některých modelů, můžete je skrýt v nastavení.

                <Tip style="margin-top: 20px" text="Nastavte si, jaké předpovědní modely chcete zobrazovat."></Tip>
            </p>

            <h6 style="margin-top: 20px">Ostatní data</h6>
            <p>
                Zdrojem dat pro dolní tabulku s obecnými informacemi o teplotě, větru apod. je německý model ICON D2 pro
                střední Evropu.
            </p>
        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {ForecastColors} from "@/config/forecast-colors";
import SkyQuality from "@/components/forecast/SkyQuality";
import ForecastReliabilityScaleLegend from "@/components/forecast/ForecastReliabilityScaleLegend.vue";
import Tip from "@/components/Tip.vue";

export default {
    name: "ForecastSearchLegend",
    computed: {
        ...mapGetters('forecastsStore', ['isForeignLocation']),
    },
    methods: {
        ...mapActions('forecastsStore', []),
        getShadesOfReliability() {
            return [...ForecastColors.shadesOfReliability].reverse();
        },
        getShadeStyle(shade) {
            return "text-align: center; backgroundColor: " + shade;
        },
    },
    components: {
        Tip,
        ForecastReliabilityScaleLegend,
        SkyQuality
    }
}

</script>

<style scoped>

</style>
