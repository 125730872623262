<template>
    <div>

        <picture>
            <source media="(min-width: 1500px)" srcset="../assets/images/sky_2_largest.jpg">
            <source media="(min-width: 1000px)" srcset="../assets/images/sky_2_largest.jpg">
            <source media="(min-width: 500px)" srcset="../assets/images/sky_2_largest.jpg">
            <source media="(min-width: 100px)" srcset="../assets/images/sky_2_largest_narrow.jpg">
            <img src="../assets/images/sky_2_largest.jpg" alt="background image"/>
        </picture>

        <NavBar></NavBar>

        <!--        :key="$route.fullPath" .. important for this reason:
                    https://stackoverflow.com/questions/64682708/how-to-force-vue-to-destroy-and-re-create-the-component-on-route-change -->
        <router-view :key="$route.fullPath" style="margin-bottom: 50px"></router-view>

        <Footer></Footer>

    </div>
</template>


<script>
import NavBar from "@/components/NavBar";
import {mapActions, mapGetters} from "vuex";
import Footer from "@/components/Footer";
import {narrowViewPortMixin} from "@/components/reusables/narrow-view-port-mixin";
import {AppConfig} from "@/config/app-config";
import {ApiClient} from "@/api/calculations/api-client";

export default {
    name: 'App',
    mixins: [narrowViewPortMixin],
    data() {
        return {
            dataRefreshTimerId: null
        }
    },
    created() {
        console.log("created app")
        ApiClient.postAccess();
        this.loadDataForWholeCountry();
        this.dataRefreshTimerId = setInterval(() => {
                // console.log("DATA REFRESH " + new Date().toLocaleString("cs-CZ"))
            // this.refreshData()
        }, AppConfig.dataRefreshIntervalMillis());
    },
    destroyed() {
        if (this.dataRefreshTimerId) {
            setTimeout(() => {
                    clearInterval(this.dataRefreshTimerId);
                    console.log("STOPPED DATA REFRESH ...")
                },
                0);
        }
    },
    computed: {
        ...mapGetters('verificationsStore', ['showFavouritesLimitReachedDialog', 'getDialogHeader', 'getDialogMessage'])
    },
    methods: {
        ...mapActions('verificationsStore', ['loadDataForWholeCountry', 'hideFavouritesLimitReachedDialog', 'hideErrorDialog', 'refreshData']),
        onFavouritesLimitReachedDialogEvent() {
            this.hideFavouritesLimitReachedDialog();
        },

        onHideErrorDialog() {
            this.hideErrorDialog();
        }
    },
    components: {NavBar, Footer}
}

</script>

<style>

/*@import './assets/styles/calc.css';*/

</style>

<style lang="scss">

//@import 'node_modules/bootstrap/scss/bootstrap.scss';
//@import 'node_modules/bootstrap-vue/src/index.scss';


</style>
