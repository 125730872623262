<template>
        <div class="links-popup popup-text">
            <h4 style="text-align: center">Užitečné odkazy pro tuto polohu</h4>
            <hr style="margin-top: 0;">
            <div class="popup-text">
                <h6 class="link-header">Meteoblue</h6>
                <ul>
                    <li class="link">
                        <a :href="getMeteoblue14DaysLink()" @click.stop target="_blank" rel="noreferrer">
                            14 denní předpověď
                        </a>
                    </li>
                    <li class="link">
                        <a :href="getMeteoblueOpticalAerosolDepth()" @click.stop target="_blank" rel="noreferrer">
                            Čistota ovzduší
                        </a>
                    </li>
                    <li class="link">
                        <a :href="getMeteoblueMultimodelLink()" @click.stop target="_blank" rel="noreferrer">
                            Multimodel
                        </a>
                    </li>
                    <li class="link">
                        <a :href="getMeteoblueSatelliteLink()" @click.stop target="_blank" rel="noreferrer">
                            Satelit
                        </a>
                    </li>
                    <li class="link">
                        <a :href="getMeteoblueSeeingLink()" @click.stop target="_blank" rel="noreferrer">
                            Seeing
                        </a>
                    </li>
                </ul>

                <h6 class="link-header">Windy</h6>

                <ul>
                    <li class="link">
                        <a :href="getWindyMeteoradar()" @click.stop target="_blank" rel="noreferrer">
                            Meteoradar
                        </a>
                    </li>
                    <li class="link">
                        <a :href="getWindySatelliteLink()" @click.stop target="_blank" rel="noreferrer">
                            Satelit
                        </a>
                    </li>
                </ul>
            </div>
        </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {Utils} from "@/utils/Utils";

export default {
    name: "LinksPopup",
    computed: {
        ...mapGetters('forecastsStore', ['getLatitude', 'getLongitude']),
    },
    watch: {
    },
    data() {
        return {
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['']),
        getMeteoblueSeeingLink() {
            if (this.getLatitude && this.getLongitude) {
                // https://www.meteoblue.com/en/weather/outdoorsports/seeing/49.693N14.229E
                return `https://www.meteoblue.com/en/weather/outdoorsports/seeing/${this.getLatitude}N${this.getLongitude}E`;
            } else {
                return "https://www.meteoblue.com/en/weather/outdoorsports/seeing";
            }
        },
        getMeteoblue14DaysLink() {
            if (this.getLatitude && this.getLongitude) {
                // https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/po%C4%8Das%C3%AD-na-14-dn%C3%AD/50.088N14.421E
                return `https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/po%C4%8Das%C3%AD-na-14-dn%C3%AD/${this.getLatitude}N${this.getLongitude}E`;
            } else {
                return "https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/po%C4%8Das%C3%AD-na-14-dn%C3%AD/";
            }
        },
        getMeteoblueSatelliteLink() {
            let zoom = this.getZoom();
            if (this.getLatitude && this.getLongitude) {
                return `https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/maps/index#coords=${zoom}/${this.getLatitude}/${this.getLongitude}&map=satellite~sat~none~none~none`;
            } else {
                return `https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/maps/index#coords=${zoom}/49.75/16.00/&map=satellite~sat~none~none~none`;
            }
        },
        getMeteoblueOpticalAerosolDepth() {
            let zoom = this.getZoom();
            if (this.getLatitude && this.getLongitude) {
                // // https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/maps/praha_%c4%8cesko_3067696#coords=7.13/49.832/15.93&map=aerosolOpticalDepth~hourly~auto~atmos%20col~none
                return `https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/maps#coords=${zoom}/${this.getLatitude}/${this.getLongitude}&map=aerosolOpticalDepth~hourly~auto~atmos%20col~none`;
            } else {
                return `https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/maps#coords=${zoom}/49.75/16.00&map=aerosolOpticalDepth~hourly~auto~atmos%20col~none`;
            }
        },
        getMeteoblueMultimodelLink() {
            if (this.getLatitude && this.getLongitude) {
                // https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/p%C5%99edpov%C4%9B%C4%8F/multimodel/50.088N14.421E
                return `https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/p%C5%99edpov%C4%9B%C4%8F/multimodel/${this.getLatitude}N${this.getLongitude}E`;
            } else {
                return "https://www.meteoblue.com/cs/po%C4%8Das%C3%AD/p%C5%99edpov%C4%9B%C4%8F/multimodel/";
            }
        },
        getWindySatelliteLink() {
            let zoom = this.getZoomWindy();
            if (this.getLatitude && this.getLongitude) {
                // https://www.windy.com/cs/-Satelit-satellite?satellite,49.884,16.023,8
                return `https://www.windy.com/cs/-Satelit-satellite?satellite,${this.getLatitude},${this.getLongitude},${zoom}`;
            } else {
                return `https://www.windy.com/cs/-Satelit-satellite?satellite,49.884,16.023,${zoom}`;
            }
        },
        getWindyMeteoradar() {
            let zoom = this.getZoomWindy();
            if (this.getLatitude && this.getLongitude) {
                // https://www.windy.com/cs/-Satelit-satellite?satellite,49.884,16.023,8
                return `https://www.windy.com/cs/-Meteoradar-radar?radar,${this.getLatitude},${this.getLongitude},${zoom}`;
            } else {
                return `https://www.windy.com/cs/-Meteoradar-radar?radar,49.884,16.023,${zoom}`;
            }
        },
        getZoom() {
            const width = Utils.getScreenWidth();
            const zoom = width === 'small' ? 5.3 : width === 'medium' ? 6.0 : 6.78;
            return zoom;
        },
        getZoomWindy() {
            const width = Utils.getScreenWidth();
            const zoom = width === 'small' ? 5 : width === 'medium' ? 6.0 : 7;
            return zoom;
        }

    },
    components: {
    }
}
</script>

<style scoped>

.link {
    margin-bottom: 7px;
    margin-left: 15px;
}

.link-header {
    margin-left: 25px;
    //text-align: center;
}

.links-popup {
    width: 150px;
    position: fixed;
    bottom: 85px;
    right: 20px;
    background-color: rgb(245, 245, 245);
    border: 1px solid #ccc;
    border-radius: 5%;
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    z-index: 10000;
}


@media (max-width: 699px) {
    .links-popup {
        width: 260px;
    }
}

@media (min-width: 700px) {
    .links-popup {
        width: 350px;
    }
}

</style>
