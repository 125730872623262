<template>

    <div style="margin-top: 40px">
        <h6 class="centered" style="font-size: 22px; font-weight: 600;">Dlouhodobá spolehlivost modelů</h6>

        <div class="row" style="margin-top: 20px">
            <div class="col-sm-6">
                <ForecastLongTermVerificationsChart style="margin-bottom: 25px"></ForecastLongTermVerificationsChart>
            </div>
            <div class="col-sm-6">
                <p style="margin-bottom: 20px">Graf zobrazuje průměrné denní odchylky předpovědí od skutečného stavu oblačnosti
                    a vychází z dat dostupných pro <span style="font-weight: 600">širší region vyhledané lokality</span>.
                </p>
                <ForecastLongTermVerificationsChartOptions></ForecastLongTermVerificationsChartOptions>
            </div>
        </div>
    </div>

</template>

<script>
import {mapGetters} from 'vuex';
import ForecastLongTermVerificationsChart from "@/components/forecast/ForecastLongTermVerificationsChart";
import ForecastLongTermVerificationsChartOptions from "@/components/forecast/ForecastLongTermVerificationsChartOptions";

export default {
    name: "ForecastLongTermVerifications",
    mounted() {
        window.scrollTo(0, 0)
    },
    computed: {
        ...mapGetters('verificationsStore', ['getPageTitle'])
    },
    methods: {},
    // eslint-disable-next-line vue/no-unused-components
    components: {
        ForecastLongTermVerificationsChartOptions,
        ForecastLongTermVerificationsChart
    }
}

</script>
