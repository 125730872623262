<template>

    <div class="d-flex justify-content-center align-items-center">
        <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
                    id="cloud-option-radios"
                    v-model="selected"
                    :options="getCloudOptions"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    size="sm"
                    name="radio-btn-outline"
                    buttons
            ></b-form-radio-group>
        </b-form-group>
    </div>
</template>

<script>

// Video on  https://www.youtube.com/watch?v=N8-p9bdYWqs&ab_channel=ChartJS

import {mapActions, mapGetters} from "vuex";
import {BFormGroup, BFormRadioGroup} from 'bootstrap-vue';

export default {
    name: "ForecastCloudOptions",
    computed: {
        ...mapGetters('forecastsStore', ['getCloudOptions', 'getDisplayedCloudType']),
    },
    watch: {
        selected: function () {
            // console.log("selected: " + this.selected)
            this.changeDisplayedCloudType(this.selected);
        }
    },
    data() {
        return {
            selected: 'radio1',
        }
    },
    methods: {
        ...mapActions('forecastsStore', ['changeDisplayedCloudType']),
    },
    mounted() {
        this.selected = this.getDisplayedCloudType;
    },
    components: {
        BFormGroup, BFormRadioGroup
    }
}
</script>

<style>
</style>
